import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function UserCreate() {
  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({ detail: [] });
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL;

  const postUser = (event) => {
    event.preventDefault();

    if (!username || username.length < 4) {
      setError({ detail: ["아이디는 4자리 이상이어야 합니다."] });
      return;
    }

    const url = `${API_URL}/api/user/create`;
    const params = {
      username: username,
      password1: password1,
      password2: password2,
      email: email,
    };

    axios.post(url, params)
      .then(() => {
        navigate('/user/login');
      })
      .catch((err) => {
        if (err.response) {
          const errorDetails = Array.isArray(err.response.data.detail)
            ? err.response.data.detail.map(detail => detail.msg || 'An error occurred.')
            : [err.response.data.detail || 'An error occurred.'];
          setError({ detail: errorDetails });
        } else {
          setError({ detail: ["An error occurred. Please try again."] });
          console.error("Error:", err.message);
        }
      });
  };
  return (
    <main className="form-signup w-100 m-auto">
      <form onSubmit={postUser} className="form-box">
        <h1 className="h3 mb-3 fw-normal">회원 가입</h1>
        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="floatingUsername"
            placeholder="아이디"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="floatingUsername">아이디</label>
        </div>
        <div className="form-floating mb-2">
          <input
            type="password"
            className="form-control"
            id="floatingPassword1"
            placeholder="비밀번호"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
          <label htmlFor="floatingPassword1">비밀번호</label>
        </div>
        <div className="form-floating mb-2">
          <input
            type="password"
            className="form-control"
            id="floatingPassword2"
            placeholder="비밀번호 확인"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <label htmlFor="floatingPassword2">비밀번호 확인</label>
        </div>
        <div className="form-floating mb-2">
          <input
            type="email"
            className="form-control"
            id="floatingEmail"
            placeholder="이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="floatingEmail">이메일</label>
        </div>
        <button className="btn btn-primary w-100 py-2" type="submit">회원가입</button>
        {/* 에러 메시지를 표시하는 부분 */}
        {error.detail.length > 0 && (
          <div style={{ padding: '5px 10px' }}>
            {error.detail.map((msg, index) => (
              <p key={index} style={{ fontSize: '14px', marginBottom: '0', color: '#ff2727'}}>{msg}</p>
            ))}
          </div>
        )}
      </form>
    </main>
  );
}

export default UserCreate;