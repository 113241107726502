import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function UserPage() {
  const { username, isLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate('/user/login'); 
    }
  }, [isLogin, navigate]);

  return (
    <div className="container">
      <h1 className="my-3">마이페이지</h1>
      <p>환영합니다, {username}님!</p>
    </div>
  );
}

export default UserPage;
