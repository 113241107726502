import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ko';
import { AuthContext } from '../../context/AuthContext';  // 로그인 정보 사용

moment.locale('ko');

function QuestionIndex() {
  const { isLogin } = useContext(AuthContext);  // 로그인 상태를 Context에서 가져옴
  const [questionList, setQuestionList] = useState([]);
  const [size] = useState(10);
  const [total, setTotal] = useState(0);
  const [kw, setKw] = useState('');
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const totalPage = Math.ceil(total / size);

  useEffect(() => {
    getQuestionList();
  }, [page, keyword]);

  const getQuestionList = async () => {
    const params = {
      page,
      size,
      keyword
    };
    try {
      const response = await axios.get(`${API_URL}/api/question`, { params });
      const json = response.data;
      setQuestionList(json.question_list);
      setTotal(json.total);
      setKw(keyword);
    } catch (error) {
      console.error("Failed to fetch question list:", error);
    }
  };

  const handleSearch = () => {
    setKeyword(kw);
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPage) {
      setPage(newPage);
    }
  };

  const handleQuestionCreate = () => {
    if (isLogin) {
      navigate('/question/create');
    } else {
      navigate('/user/login');
    }
  };

  return (
    <div className="container my-3">
      <div className="row my-3">
        <div className="col-6">
          <button className="btn btn-primary" onClick={handleQuestionCreate}>
            질문 등록하기
          </button>
        </div>
        <div className="col-6">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={kw}
              onChange={(e) => setKw(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              onClick={handleSearch}
            >
              찾기
            </button>
          </div>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr className="text-center table-dark">
            <th>번호</th>
            <th style={{ width: '50%' }}>제목</th>
            <th>글쓴이</th>
            <th>작성일시</th>
          </tr>
        </thead>
        <tbody>
          {questionList.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">등록된 질문이 없습니다.</td>
            </tr>
          ) : (
            questionList.map((question, i) => (
              <tr className="text-center" key={question.id}>
                <td>{total - (page * size) - i}</td>
                <td className="text-start">
                  <Link to={`detail/${question.id}`}>{question.subject}</Link>
                  {question.answers && question.answers.length > 0 && (
                    <span className="text-danger small mx-2">{question.answers.length}</span>
                  )}
                </td>
                <td>{question.user ? question.user.username : ""}</td>
                <td>{moment(question.create_date).format("YYYY년 MM월 DD일 hh:mm")}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <ul className="pagination justify-content-center">
        {/* Previous Page */}
        <li className={`page-item ${page <= 0 && 'disabled'}`}>
          <button className="page-link" onClick={() => handlePageChange(page - 1)}>이전</button>
        </li>
        {/* Page Numbers */}
        {Array.from({ length: totalPage }, (_, loopPage) => (
          loopPage >= page - 5 && loopPage <= page + 5 && (
            <li key={loopPage} className={`page-item ${loopPage === page && 'active'}`}>
              <button onClick={() => handlePageChange(loopPage)} className="page-link">{loopPage + 1}</button>
            </li>
          )
        ))}
        {/* Next Page */}
        <li className={`page-item ${page >= totalPage - 1 && 'disabled'}`}>
          <button className="page-link" onClick={() => handlePageChange(page + 1)}>다음</button>
        </li>
      </ul>
    </div>
  );
}

export default QuestionIndex;
