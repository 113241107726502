import React from 'react';

function Footer() {
  return (
    <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div className="container">
        <div className="row">
          <div className="col-md-4 d-flex align-items-center">
            <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
              <svg className="bi" width="30" height="24">
                {/* 여기서는 실제 아이콘을 사용하거나 적절한 아이콘 라이브러리를 적용해야 합니다 */}
              </svg>
            </a>
            <span className="text-muted" style={{ fontSize: '0.85rem', whiteSpace: 'nowrap' }}>
              2024 ©BrewChips All rights reserved
              <br />
              <p style={{ fontSize: '0.85rem', margin: 0 }}>
                비즈니스 관련 문의 : contact@chat-da.com
              </p>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
