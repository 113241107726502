import React from 'react';
import Header from './Header';
import Footer from './Footer';

function MainLayout({ children }) {
  return (
    <div className="main-layout">
      <Header />
      
      <div className="content-container">
        {children}  {/* 페이지별 콘텐츠가 여기에 들어갑니다 */}
      </div>
      
      <Footer />
    </div>
  );
}

export default MainLayout;
