import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

function QuestionCreate() {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const { accessToken } = useContext(AuthContext); // accessToken을 AuthContext에서 가져옴
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const postQuestion = (event) => {
    event.preventDefault();
    const url = `${API_URL}/api/question/create`;
    const params = {
      subject: subject,
      content: content,
    };

    axios.post(url, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`, // 토큰을 헤더에 포함
      },
    })
      .then(() => {
        navigate('/question');
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          navigate('/user/login');
        } else {
          console.error("Failed to create question:", err);
        }
      });
  };

  return (
    <div className="container">
      <h5 className="my-3 border-bottom pb-2">질문 등록</h5>
      <form onSubmit={postQuestion} className="my-3">
        <div className="mb-3">
          <label htmlFor="subject">제목</label>
          <input
            type="text"
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="content">내용</label>
          <textarea
            className="form-control"
            rows="10"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">등록</button>
      </form>
    </div>
  );
}

export default QuestionCreate;
