import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserCreate from '../components/users/UserCreate';
import UserLogin from '../components/users/UserLogin';
import UserPage from '../components/users/UserPage';

function UserRoutes() {
  return (
    <Routes>
      <Route path="/user/create" element={<UserCreate />} />
      <Route path="/user/login" element={<UserLogin />} />
      <Route path="/user/mypage" element={<UserPage />} /> 
    </Routes>
  );
}

export default UserRoutes;
