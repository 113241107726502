import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
// 페이지별로 필요한 스타일이나 레이아웃을 추가적으로 import할 수 있습니다.

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

    </Routes>
  );
}

export default MainRoutes;
