import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { marked } from 'marked';
import moment from 'moment';
import 'moment/locale/ko';
import { AuthContext } from '../../context/AuthContext';

moment.locale('ko');

function QuestionDetail() {
  const { id } = useParams();
  const [question, setQuestion] = useState({ answers: [], voter: [], content: '' });
  const [content, setContent] = useState('');
  const { isLogin, username, accessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getQuestion();
  }, [id]);

  const getQuestion = () => {
    axios.get(`${API_URL}/api/question/detail/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })
      .then(response => {
        setQuestion(response.data);
      })
      .catch(err => {
        console.error("Failed to fetch question detail:", err);
      });
  };

  const postAnswer = (event) => {
    event.preventDefault();
    axios.post(`${API_URL}/api/answer/create/${id}`, { content }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })
      .then(() => {
        setContent('');
        getQuestion();
      })
      .catch(err => {
        console.error("Failed to post answer:", err);
      });
  };

  const deleteQuestion = (question_id) => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      axios.delete(`${API_URL}/api/question/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { question_id }
      })
        .then(() => {
          navigate('/');
        })
        .catch(err => {
          console.error("Failed to delete question:", err);
        });
    }
  };

  const deleteAnswer = (answer_id) => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      axios.delete(`${API_URL}/api/answer/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { answer_id }
      })
        .then(() => {
          getQuestion();
        })
        .catch(err => {
          console.error("Failed to delete answer:", err);
        });
    }
  };

  const voteQuestion = (question_id) => {
    if (window.confirm('정말로 추천하시겠습니까?')) {
      axios.post(`${API_URL}/api/question/vote`, { question_id }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      })
        .then(() => {
          getQuestion();
        })
        .catch(err => {
          console.error("Failed to vote question:", err);
        });
    }
  };

  return (
    <div className="container my-3">
      <h2 className="border-bottom py-2">{question.subject}</h2>
      <div className="card my-3">
        <div className="card-body">
          <div className="card-text" dangerouslySetInnerHTML={{ __html: marked.parse(question.content) }} />
          <div className="d-flex justify-content-end">
            {question.modify_date && (
              <div className="badge bg-light text-dark p-2 text-start mx-3">
                <div className="mb-2">modified at</div>
                <div>{moment(question.modify_date).format("YYYY년 MM월 DD일 HH:mm")}</div>
              </div>
            )}
            <div className="badge bg-light text-dark p-2 text-start">
              <div className="mb-2">{question.user ? question.user.username : ""}</div>
              <div>{moment(question.create_date).format("YYYY년 MM월 DD일 HH:mm")}</div>
            </div>
          </div>
          <div className="my-3">
            <button className="btn btn-sm btn-outline-secondary" onClick={() => voteQuestion(question.id)}>
              추천
              <span className="badge rounded-pill bg-success">{question.voter.length}</span>
            </button>
            {question.user && username === question.user.username && (
              <>
                <Link to={`/question-modify/${question.id}`} className="btn btn-sm btn-outline-secondary">수정</Link>
                <button className="btn btn-sm btn-outline-secondary" onClick={() => deleteQuestion(question.id)}>삭제</button>
              </>
            )}
          </div>
        </div>
      </div>

      <button className="btn btn-secondary" onClick={() => navigate('/question')}>목록으로</button>

      <h5 className="border-bottom my-3 py-2">{question.answers.length}개의 답변이 있습니다.</h5>
      {question.answers.map(answer => (
        <div className="card my-3" key={answer.id}>
          <div className="card-body">
            <div className="card-text" dangerouslySetInnerHTML={{ __html: marked.parse(answer.content) }} />
            <div className="d-flex justify-content-end">
              {answer.modify_date && (
                <div className="badge bg-light text-dark p-2 text-start mx-3">
                  <div className="mb-2">modified at</div>
                  <div>{moment(answer.modify_date).format("YYYY년 MM월 DD일 hh:mm")}</div>
                </div>
              )}
              <div className="badge bg-light text-dark p-2 text-start">
                <div className="mb-2">{answer.user ? answer.user.username : ""}</div>
                <div>{moment(answer.create_date).format("YYYY년 MM월 DD일 hh:mm")}</div>
              </div>
            </div>
            {answer.user && username === answer.user.username && (
              <>
                <Link to={`/answer/update/${answer.id}`} className="btn btn-sm btn-outline-secondary">수정</Link>
                <button className="btn btn-sm btn-outline-secondary" onClick={() => deleteAnswer(answer.id)}>삭제</button>
              </>
            )}
          </div>
        </div>
      ))}

      <form onSubmit={postAnswer} className="my-3">
        <div className="mb-3">
          <textarea
            rows="2"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            disabled={!isLogin}
            className="form-control"
          />
        </div>
        <button type="submit" className={`btn btn-primary ${!isLogin ? 'disabled' : ''}`}>답변등록</button>
      </form>
    </div>
  );
}

export default QuestionDetail;
