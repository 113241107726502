import React from 'react';

function Home() {
  return (
    <div className="cover-container d-flex w-100 h-100 px-3 py-5 mx-auto text-center flex-column responsive-text">
      <main className="px-3 py-5 content-box">
        <h1 className="py-3"><strong>모두의 도구</strong></h1>
        <p className="animate__animated animate__fadeInUp lead py-3 fs-5 responsive-text" style={{ whiteSpace: 'normal', overflow: 'visible' }}>
          우리는 모든 사람들이 업무를 할 때 찾는 사이트가 되고자 합니다.&nbsp;<br />
          모든 웹 도구는 언제 어디서나 사용 가능하며, 무료로 제공됩니다.&nbsp;<br />
          &nbsp;<br />
        </p>
      </main>
    </div>
  );
}

export default Home;
