import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function AnswerModify() {
  const { answer_id } = useParams();
  const [questionId, setQuestionId] = useState(0);
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`/api/answer/detail/${answer_id}`)
      .then(response => {
        const json = response.data;
        setQuestionId(json.question_id);
        setContent(json.content);
      })
      .catch(err => {
        console.error("Failed to fetch answer details:", err);
      });
  }, [answer_id]);

  const updateAnswer = (event) => {
    event.preventDefault();
    const url = '/api/answer/update';
    const params = {
      answer_id: answer_id,
      content: content,
    };
    
    axios.put(url, params)
      .then(() => {
        navigate(`/detail/${questionId}`);
      })
      .catch(err => {
        console.error("Failed to update answer:", err);
      });
  };

  return (
    <div className="container">
      <h5 className="my-3 border-bottom pb-2">답변 수정</h5>
      <form onSubmit={updateAnswer} className="my-3">
        <div className="mb-3">
          <label htmlFor="content">내용</label>
          <textarea 
            className="form-control" 
            rows="10" 
            value={content} 
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">수정하기</button>
      </form>
    </div>
  );
}

export default AnswerModify;
