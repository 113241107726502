import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import MainLayout from './components/layout/MainLayout';
import QuestionRoutes from './routes/QuestionRoutes';
import UserRoutes from './routes/UserRoutes';
import MainRoutes from './routes/MainRoutes';
import { AuthProvider } from './context/AuthContext';
import './style_new.css';

const ToolRoutes = lazy(() => import('./routes/ToolRoutes'));

function App() {
  return (
    <AuthProvider>
      <Router>
        <MainLayout>
          <Suspense fallback={<div>Loading...</div>}>
            <ToolRoutes />
          </Suspense>
          <QuestionRoutes />
          <MainRoutes />
          <UserRoutes />
        </MainLayout>
      </Router>
    </AuthProvider>
  );
}

export default App;
