import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function QuestionModify() {
  const { question_id } = useParams();
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`/api/question/detail/${question_id}`)
      .then(response => {
        setSubject(response.data.subject);
        setContent(response.data.content);
      })
      .catch(err => {
        // 에러 발생 시 별도로 처리하지 않음 (ErrorBoundary에 의해 처리됨)
        console.error(err); // 오류를 콘솔에 출력해 디버깅 용도로 사용
      });
  }, [question_id]);

  const updateQuestion = (event) => {
    event.preventDefault();
    const url = '/api/question/update';
    const params = {
      question_id: question_id,
      subject: subject,
      content: content,
    };

    axios.put(url, params)
      .then(() => {
        navigate(`/detail/${question_id}`);
      })
      .catch((err) => {
        // 에러 발생 시 별도로 처리하지 않음 (ErrorBoundary에 의해 처리됨)
        console.error(err); // 오류를 콘솔에 출력해 디버깅 용도로 사용
      });
  };

  return (
    <div className="container">
      <h5 className="my-3 border-bottom pb-2">질문 수정</h5>
      <form onSubmit={updateQuestion} className="my-3">
        <div className="mb-3">
          <label htmlFor="subject">제목</label>
          <input
            type="text"
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="content">내용</label>
          <textarea
            className="form-control"
            rows="10"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">수정하기</button>
      </form>
    </div>
  );
}

export default QuestionModify;