import React from 'react';
import { Route, Routes } from 'react-router-dom';
import QuestionIndex from '../components/questions/QuestionIndex';
import QuestionDetail from '../components/questions/QuestionDetail';
import QuestionCreate from '../components/questions/QuestionCreate';
import QuestionModify from '../components/questions/QuestionModify';
import AnswerModify from '../components/questions/AnswerModify';
// 페이지별로 필요한 스타일이나 레이아웃을 추가적으로 import할 수 있습니다.

function QuestionRoutes() {
  return (
    <Routes>
      <Route path="/question" element={<QuestionIndex />} />
      <Route path="/question/detail/:id" element={<QuestionDetail />} />
      <Route path="/question/create" element={<QuestionCreate />} />
      <Route path="/question/modify/:id" element={<QuestionModify />} />
      <Route path="/answer/modify/:id" element={<AnswerModify />} />
    </Routes>
  );
}

export default QuestionRoutes;
