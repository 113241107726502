import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

function UserLogin() {
  const [username, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ detail: [] });
  const navigate = useNavigate();
  const { setIsLogin, setUsername, setAccessToken } = useContext(AuthContext);

  const API_URL = process.env.REACT_APP_API_URL;

  const loginUser = (event) => {
    event.preventDefault();

    // 클라이언트 측에서 아이디와 비밀번호가 입력되었는지 확인
    if (!username || !password) {
      const errorDetails = [];
      if (!username) {
        errorDetails.push("아이디를 입력해주세요.");
      }
      if (!password) {
        errorDetails.push("비밀번호를 입력해주세요.");
      }
      setError({ detail: errorDetails });
      return; // 폼 전송 중단
    }

    const url = `${API_URL}/api/user/login`;
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    axios.post(url, params)
      .then((response) => {
        const { access_token, username } = response.data;

        // 로그인 성공 처리
        setIsLogin(true);
        setUsername(username);
        setAccessToken(access_token);

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('username', username);

        // 메인 페이지로 이동
        navigate('/');
      })
      .catch((err) => {
        // 서버에서 반환된 에러 메시지를 처리
        if (err.response && err.response.data.detail) {
          setError({ detail: [err.response.data.detail] });
        } else {
          setError({ detail: ["아이디 또는 비밀번호를 확인하세요"] });
        }
        console.error("Error:", err.message);
      });
  };

  return (
    <main className="form-signin w-100 m-auto">
      <form onSubmit={loginUser}>
        <h1 className="h3 mb-3 fw-normal">로그인</h1>
        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsernameInput(e.target.value)}
          />
          <label htmlFor="floatingInput">아이디</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">비밀번호</label>
        </div>

        <div className="form-check text-start my-3">
          <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault" />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            아이디 저장 
          </label>
        </div>
        <button className="btn btn-primary w-100 py-2" type="submit">로그인</button>

        {/* 에러 메시지를 표시하는 부분 */}
        {error.detail.length > 0 && (
          <div style={{ padding: '5px 10px' }}>
            {error.detail.map((msg, index) => (
              <p key={index} style={{ fontSize: '14px', marginBottom: '0', color: '#ff2727'}}>{msg}</p>
            ))}
          </div>
        )}
      </form>
    </main>
  );
}

export default UserLogin;
