import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const user = localStorage.getItem('username');
    if (token && user) {
      setIsLogin(true);
      setUsername(user);
      setAccessToken(token);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLogin, setIsLogin, username, setUsername, accessToken, setAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
};
