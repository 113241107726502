import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Header() {
  const { isLogin, username, setIsLogin, setUsername, setAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleLogout = () => {
    // 로그아웃 시, 인증 관련 상태를 초기화하고 메인 페이지로 이동
    setAccessToken('');
    setUsername('');
    setIsLogin(false);
    localStorage.removeItem('access_token');  // 로컬스토리지에서 토큰 제거
    localStorage.removeItem('username');      // 로컬스토리지에서 사용자명 제거
    navigate('/');  // 메인 페이지로 이동
    setShowOffcanvas(false); // 오프캔버스 닫기
  };

  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={() => navigate('/')}>
          모두의도구
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleOffcanvasShow}
          aria-controls="offcanvasNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* 데스크탑용 네비게이션 메뉴 */}
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/tool">도구</Link>
            </li>
            <li>
              <Link className="nav-link" to="/question">자유게시판</Link>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {isLogin ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/user/mypage">마이페이지</Link>
                </li>
                <li className="nav-item">
                  <button className="nav-link btn" onClick={handleLogout}>
                    로그아웃 ({username})
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/user/create">회원가입</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/user/login">로그인</Link>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* 모바일 환경에서 나타나는 Offcanvas */}
        <Offcanvas show={showOffcanvas} onHide={handleOffcanvasClose} placement="end" style={{ width: '60%' }}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>모두의도구</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/tool" onClick={handleOffcanvasClose}>도구</Link>
              </li>
              <li>
                <Link className="nav-link" to="/question" onClick={handleOffcanvasClose}>자유게시판</Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {isLogin ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/user/mypage" onClick={handleOffcanvasClose}>마이페이지</Link>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link btn" onClick={handleLogout}>
                      로그아웃 ({username})
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/user/create" onClick={handleOffcanvasClose}>회원가입</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/user/login" onClick={handleOffcanvasClose}>로그인</Link>
                  </li>
                </>
              )}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </nav>
  );
}

export default Header;
